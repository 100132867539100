<template>
  <v-card class="v-card-light-thin px-0  v-card v-sheet theme--light elevation-0">
    <v-list>
      <v-list-item
        v-for="action in filteredActions"
        :key="action.index"
        @click="$router.push(action.link)"
      >
        <v-list-item-avatar
          width="15"
          min-width="15"
          height="37"
        >
          <v-icon class="theme-primary-color">
            {{ action.icon }}
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-action>
          <v-list-item-title
            class="fw300"
            style="margin-top: 1px;"
          >
            {{ action.text }}
            <v-badge
              v-if="action.new"
              style="margin-top:0px; margin-bottom: 0px;"
              class="theme-badge mt-0"
              :content="$t('Nuevo')"
              inline
            />
          </v-list-item-title>
        </v-list-item-action>

        <div class="border-bottom" />
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
  export default {
    name: 'ProfileMenu',
    components: {},
    data: function () {
      return {
      }
    },
    computed: {
      actions () {
        let links = [
          { link: '/users/profile', text: this.$t('Mi perfil').toString(), icon: 'fas fa-user', new: false },
          { link: '/developers/waba-phone-detail', text: this.$t('Mi perfil WhatsApp').toString(), icon: 'fab fa-whatsapp', new: false, showOnlyIfWhiteLabel: true },
        ]

        if (this.$permissions.hasPermission('view-nia-profile')) {
          links.push({ link: '/users/supplementary-data', text: this.$t('Mi perfil IA').toString(), icon: 'fas fa-brain', new: false, showOnlyIfWhiteLabel: true })
        }

        if (this.$permissions.hasPermission('view-invoices')) {
          links.push({ link: '/users/billing', text: this.$t('Mis facturas').toString(), icon: 'fas fa-list-alt', new: false })
        }


        links.push({ link: '/users/plans', text: this.$t('Mis planes').toString(), icon: 'fas fa-clipboard-check', new: false, showOnlyIfWhiteLabel: true })

        if (this.$permissions.hasPermission('create-payments')) {
          links.push({ link: '/payments', text: this.$t('Añadir saldo').toString(), icon: 'fas fa-credit-card', new: false })
        }

        if (this.$permissions.hasPermission('view-balance-alerts')) {
          links.push({ link: '/users/balance', text: this.$t('Alertas de saldo').toString(), icon: 'fas fa-bell', new: false })
        }

        const moreLinks = [
          { link: '/users/plans-and-prices', text: this.$t('Mis tarifas').toString(), icon: 'fas fa-dollar-sign', new: false },
          { link: '/users/change-password', text: this.$t('Cambiar password').toString(), icon: 'fas fa-key', new: false },
        ]

        if (this.$permissions.hasPermission('view-billing-profile')) {
          links.push({ link: '/users/billing-profile', text: this.$t('Perfil fiscal').toString(), icon: 'fas fa-briefcase', new: false })
        }


        links.push(
          { link: '/users/contact-data', text: this.$t('Mis datos de contacto').toString(), icon: 'fas fa-id-card-alt', new: false },
        )


        links = links.concat(moreLinks)
        return links
      },
      filteredActions () {
        return this.actions.filter(action => {
          return action.showOnlyIfWhiteLabel === undefined || (action.showOnlyIfWhiteLabel && this.$isMainWhiteLabel)
        })
      },
    },

    watch: {
    },

    mounted () {

    },
    created () {

    },
    methods: {
    },

  }
</script>
